:root {
  --dark: rgb(10, 10, 11);
  --dark-60: rgba(10, 10, 11, 0.61);
  --dark-40: rgba(10, 10, 11, 0.4);
  --dark-20: rgb(25, 25, 25);
  --dark-10: rgba(10, 10, 11, 0.1);
  --light: rgb(250, 250, 250);
  --light-60: rgba(250, 250, 250, 0.61);
  --light-20: rgba(250, 250, 250, 0.2);
  --light-10: rgba(250, 250, 250, 0.1);
  --red: rgb(232, 72, 85);
  --red-40: rgba(232, 72, 85, 0.4);
  --scrollbar-size: 16px;
  --scrollbar-size-min: 44px;
  --bg: var(--light);
  --bg-20: var(--dark-20);
  --text: var(--dark);
  --text-60: var(--dark-60);
  --selection-color: var(--dark-10);
  --scrollbar-color: var(--dark-40);
}

[data-theme='dark'] {
  --bg: var(--dark);
  --bg-20: var(--dark-20);
  --text: var(--light);
  --text-60: var(--light-60);
  --selection-color: var(--light-10);
  --scrollbar-color: var(--light-20);
}

::selection {
  background: var(--selection-color);
  text-shadow: none;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
}

::-webkit-scrollbar-thumb {
  min-height: var(--scrollbar-size-min);
  min-width: var(--scrollbar-size-min);
  background-color: var(--scrollbar-color);
  border-radius: var(--scrollbar-size);
  border: 5px solid var(--bg);
  background-clip: padding-box;
}

@media (max-width: 775px) {
  :root {
    --container-padding: 1rem;
  }
}

@media (min-width: 775px) {
  :root {
    --container-padding: 2rem;
  }
}

@media (max-width: 490px) {
  .hidden-sm {
    display: none;
  }
}

@media (max-width: 250px) {
  .hidden-xxsm {
    display: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
}

body[data-theme='dark'] {
  color-scheme: dark;
}

body[data-theme='light'] {
  color-scheme: light;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

html,
body {
  padding: var(--container-padding);
  margin: 0;
  font-family: var(--font);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'kern';
  font-size: 15px;
  font-weight: 400;
  line-height: 1.9rem;
  color: var(--text);
  background: var(--bg);
  letter-spacing: 0.01rem;
}

header {
  display: flex;
  flex: 1;
  align-items: center;
}

header h1,
header p {
  padding: 0;
  margin: 0;
}

main {
  min-height: calc(100vh - calc(50px + 10rem));
}

section {
  animation: fadeInUp 0.3s forwards ease-in-out calc(var(--animation-order) * 100ms);
  opacity: 0;
  margin: 4rem 0;
}

footer {
  display: flex;
  flex: 1;
  padding: 2rem 0 .5rem 0;
  border-top: 2.5px dotted var(--text-60);
}

footer a {
  display: flex;
  flex-grow: 1;
}

p {
  margin: 0 0 1rem;
}

a {
  color: inherit;
  font-family: var(--stylized-font);
  font-size: 1.05rem;
  font-weight: 400;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 0.3rem;
  text-decoration-color: var(--text-60);
  text-decoration-style: wavy;
}

a:hover {
  text-decoration-color: var(--text);
}

h1,
h3 {
  font-size: 1rem;
  margin: 0;
}

h2 {
  margin: 1.6rem 0;
}

.container {
  max-width: 640px;
}

.title {
  margin-left: 1rem;
}
